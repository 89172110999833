
























































































.instructions {
    width: 90vw;
    height: 70vh;
}
